import { ResultFailType } from '@itm/shared-frontend';
export { ResultFailType };

export type {
  GlobalRole,
  SelectOption,
  ServerError,
  ServerFormErrors,
  ExternalFilters,
  TanstackTableChangeParams,
} from '@itm/shared-frontend/lib/types';

export enum TargetPlatform {
  AWS = 'AWS',
  AZURE = 'AZURE',
}

export enum Role {
  SuperAdmin = 'SuperAdmin',
  Support = 'Support',
  Analyst = 'EmployerHub_Analyst',
  Viewer = 'EmployerHub_Viewer',
  ClientAdmin = 'EmployerHub_ClientAdmin',
  Manager = 'EmployerHub_Manager',
}

export enum SourceFileStatus {
  FailedValidations = 'FailedValidations',
  CompleteWithWarnings = 'CompleteWithWarnings',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Failed = 'Failed',
}

export enum ReportStatus {
  Requested = 'Requested',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Failed = 'Failed',
}

export enum ReportType {
  TPRReport = 'TPRReport',
  DataChangesExtract = 'DataChangesExtract',
}

export enum FrequencyType {
  Monthly = 'Monthly',
  Weekly = 'Weekly',
  Fortnightly = 'Fortnightly',
  FourWeekly = 'FourWeekly',
}

export enum PayrollStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum UploadAuditStatus {
  Default = 'Default',
  Warning = 'Warning',
  Error = 'Error',
  Success = 'Success',
}

export enum Severity {
  Success = 'Success',
  Warning = 'Warning',
  Error = 'Error',
  Info = 'Info',
}

export enum PayrollValidationSeverity {
  Warning = 'Warning',
  Error = 'Error',
  Info = 'Info',
}

export enum ValidationType {
  Max = 'Max',
  Min = 'Min',
  IsNotEmpty = 'IsNotEmpty',
  IsValidDate = 'IsValidDate',
  IsDateMoreThanInYears = 'IsDateMoreThanInYears',
  IsDateBefore = 'IsDateBefore',
  IsDateAfter = 'IsDateAfter',
  IsValidDecimal = 'IsValidDecimal',
  IsValidEmail = 'IsValidEmail',
  IsDependedOn = 'IsDependedOn',
  IsMaxLength = 'IsMaxLength',
  HasChanged = 'HasChanged',
  IsInEnum = 'IsInEnum',
  IsValidRegex = 'IsValidRegex',
  IsFirstNameLessThanLimit = 'IsFirstNameLessThanLimit',
  HasSameEmployerESPR = 'HasSameEmployerESPR',
}

export enum PayPeriodStatus {
  NoDataLoaded = 'NoDataLoaded',
  DataLoaded = 'DataLoaded',
  PreppingAdminPlatformData = 'PreppingAdminPlatformData',
  DataSubmittedAdminPlatform = 'DataSubmittedAdminPlatform',
  AwaitingMoney = 'AwaitingMoney',
  DirectDebit = 'DirectDebit',
  Completed = 'Completed',
  FailedSubmitAdminPlatformData = 'FailedSubmitAdminPlatformData',
}

export enum ExitReasonCode {
  NotProvided = 'NotProvided',
  EmployeeLeftEmployment = 'EmployeeLeftEmployment',
  CeasedActiveMembership = 'CeasedActiveMembership',
  Death = 'Death',
  Retirement = 'Retirement',
  FlexibleRetirement = 'FlexibleRetirement',
  IllHealthRetirement = 'IllHealthRetirement',
}

export enum RouteAction {
  View = 'view',
  Edit = 'edit',
}

export enum PartTimeType {
  HoursPerWeek = 'HoursPerWeek',
  HoursPerDay = 'HoursPerDay',
  HoursPerMonth = 'HoursPerMonth',
  HoursPerYear = 'HoursPerYear',
  DaysPerWeek = 'DaysPerWeek',
  DaysPerMonth = 'DaysPerMonth',
  DaysPerYear = 'DaysPerYear',
  WeeksPerMonth = 'WeeksPerMonth',
  WeeksPerYear = 'WeeksPerYear',
  MonthsPerYear = 'MonthsPerYear',
}

export enum BreakInServiceType {
  A = 'A',
  LS = 'LS',
  ML = 'ML',
  PL = 'PL',
  S = 'S',
  SA = 'SA',
  SB = 'SB',
  SL = 'SL',
  US = 'US',
  UL = 'UL',
  UML = 'UML',
  AL = 'AL',
  U = 'U',
}

export enum ReportFrequency {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Fortnightly = 'Fortnightly',
  FourWeekly = 'FourWeekly',
  Monthly = 'Monthly',
}

export enum AuditMethod {
  ManualPartTimeDetails = 'ManualPartTimeDetails',
  ManualBreakInServiceDetails = 'ManualBreakInServiceDetails',
  ManualSalaryDetails = 'ManualSalaryDetails',
  BulkUpload = 'BulkUpload',
}

export type CompanyResponse = {
  id: string;
  name: string;
};

export type SchemeResponse = {
  id: string;
  companyId: string;
  name: string | null;
  schemeSrcId: string | null;
};

export type DataExtractResponse = {
  id: string;
  name: string | null;
  type: ReportType;
  status: ReportStatus;
  createdDate: string;
  lastUpdated: string | null;
  createdByName: string | null;
  schemeName: string | null;
  fileSize: number;
  reason: string | null;
};

export type DataExtractResponsePagedResponse = {
  totalCount: number;
  items: DataExtractResponse[];
};

export type SourceFileSearchResponse = {
  id: string;
  name: string;
  fileType: string;
  uploadedDate: string;
  schemeName: string;
  payrollName: string;
  uploadedByUserName: string;
  payrollPeriodEnd: string;
  payrollPeriodStart: string;
  status: string;
  payrollPeriodId: string;
  schemeId: string;
  companyId: string;
  arn: string;
  filePathWithName: string;
  fileNameId: string;
  uploadedByUserEmail: string;
  employerResponses: ShortEmployerResponse[] | null;
};

export type SourceFileHistoryErrors = {
  id: string;
  sourceFileId: string;
  payrollReference: string;
  message: string;
  propertyName: string;
  severity: string;
};

export type SourceFileDetailsDto = {
  id: string;
  name: string;
  fileType: string | null;
  uploadedDate: string;
  uploadedByUserName: string | null;
  status: SourceFileStatus | null;
  runNo: number | null;
  errorMessage: string | null;
};

export type SourceFileSearchResponsePagedResponse = {
  totalCount: number;
  items: SourceFileSearchResponse[];
};

export type ProductInfoResponse = {
  id: string;
  description: string | null;
  version: string | null;
};

export type FileUploadSummary = {
  id: string;
  name: string;
  total: string;
  memberColorClass: string;
};

export type EmployerResponse = {
  id: string;
  name: string | null;
  address1: string | null;
  address2: string | null;
  address5: string | null;
  address4: string | null;
  address3: string | null;
  postcode: string | null;
  email: string | null;
  hasPaymentPlan: boolean;
  schemeId: string;
  schemeName: string | null;
  sectionId: string | null;
  strategicPartnerId: string | null;
  updatedDate: string | null;
  createdDate: string;
  epsr: string | null;
  paymentMethod: string;
  adminPlatformSyncEnabled: boolean;
  payrollRequired: boolean;
};

export type ReportTypeResponse = {
  type: string;
  name: string;
};

export type PayrollValidationRuleResponse = {
  id: string;
  fieldName: string | null;
  code: string | null;
  validationType: ValidationType;
  value: string | null;
  message: string | null;
  resolutionHelper: string | null;
  severity: PayrollValidationSeverity;
  updatedDate: string | null;
  createdDate: string;
  isOptional: boolean;
};

export type ValidationAuditResponse = {
  id: string;
  userId: string;
  payrollValidationRuleId: string;
  userName: string | null;
  oldValue: string | null;
  newValue: string | null;
  createdDate: string;
};

export type PayrollResponse = {
  id: string;
  name: string;
  schemeId: string;
  schemeName: string;
  employerId: string;
  employerName: string;
  payrollStatus: string;
  frequencyType: string;
  payrollStatusName: string;
  start: string;
  end: string;
  cutOffDay: number;
  chaserTriggerDay: number;
  paymentDay: number;
  frequencyTypeName: string;
  createdDate: string;
  updatedDate: string | null;
  adminPlatformSyncEnabled: boolean;
  payrollPeriods: PayrollPeriodResponse[] | null;
};

export type PayrollPeriodResponse = {
  id: string;
  payrollId: string;
  payrollPeriodEnd: string;
  payrollPeriodStart: string;
  paymentDate: string;
  cutOfDate: string;
  chaserDate: string;
  status: PayPeriodStatus;
  statusText: string;
  payrollEmployeeData: PayrollEmployeeDataResponse[] | null;
};

export type PayrollEmployeeDataResponse = {
  id: string;
  payrollPeriodId: string;
  payrollReference: string | null;
  dateJoinedCompany: string;
  employeeId: string;
  eeeContRate: number;
  eerContRate: number;
  avcContRate: number;
  contractualMembership: boolean;
  dateJoinedScheme: string;
  dateOfLeaving: string;
  employeeConts: number;
  employerConts: number;
  avcConts: number;
  salaryAmount: number;
  exitReasonCode: string;
  employee: EmployeeResponse;
};

export type NextPayrollResponse = {
  schemeId: string;
  payrollId: string;
  payrollName: string;
  payrollPeriodId: string;
  payrollPeriodStart: string;
  payrollPeriodEnd: string;
  frequencyTypeName: string;
};

export type CreatePayrollModel = {
  employerId: string;
  schemeId: string;
  name: string;
  frequencyType: string;
  start: string;
  cutOffDay: number;
  chaserTriggerDay: number;
  paymentDay: number;
  payrollStatus: string;
};

export type UpdatePayrollModel = {
  id: string;
  schemeId: string;
  employerId: string;
  name: string;
  frequencyType: string;
  start: string;
  end: string;
  cutOffDay: number;
  chaserTriggerDay: number;
  paymentDay: number;
  payrollStatus: string;
};

export type SubmitPayrollPeriodDto = {
  sourceFileId: string;
  companyId: string;
  schemeId: string;
  employerId: string;
};

export type PayrollValidationResponse = {
  id: string;
  employeeId: string;
  payrollReference: string;
  employeeSurname: string;
  employeeNiNumber: string;
  validationStatus: string;
};

export type UploadAuditResponse = {
  id: string;
  stageName: string;
  description: string;
  errorMessage: string;
  timeStamp: string;
  status: UploadAuditStatus;
};

export type DetailedSourceFileResponse = {
  id: string;
  name: string | null;
  fileType: string | null;
  uploadedDate: string;
  companyId: string;
  schemeId: string;
  employerResponses: ShortEmployerResponse[] | null;
  schemeName: string | null;
  payrollName: string | null;
  uploadedByUserName: string | null;
  payrollPeriodId: string;
  payrollRequired: boolean;
  payrollPeriodEnd: string;
  payrollPeriodStart: string;
  status: SourceFileStatus;
  payPeriodStatus: PayPeriodStatus;
  payPeriodStatusText: string | null;
  totalRecords: number;
  totalErrors: number;
  totalWarnings: number;
  totalProcessed: number;
  totalEmployeesContributions: number;
  totalAvcEmployeeContributions: number;
  totalEmployersContributions: number;
  totalContributions: number;
  adminPlatformSyncEnabled: boolean;
  sourceFileEmployeeData: SourceFileEmployeeDataDto[];
  sourceFileAudit: PayrollPeriodAuditResponse[];
  errorCode: ResultFailType | null;
};

export type SourceFileEmployeeDataDto = {
  employeeId: string;
  payrollReference: string | null;
  employeeSurname: string | null;
  employeeNiNumber: string | null;
  validationStatus: Severity;
};

export type UpdatePayrollValidationRuleModel = {
  id: string;
  message?: string;
  resolutionHelper?: string;
};

export type PayrollPeriodAuditResponse = {
  stageName: string | null;
  description: string | null;
  errorMessage: string | null;
  updatedDate: string;
  status: UploadAuditStatus;
};

export type MemberSearchResponse = {
  id: string;
  membershipId: string;
  firstName: string | null;
  lastName: string | null;
  nino: string | null;
  dateOfBirth: string | null;
  schemeName: string | null;
  employerName: string | null;
  payrollReference: string | null;
};

export type MemberSearchResponsePagedResponse = {
  totalCount: number;
  items: MemberSearchResponse[];
};

export type MembershipDetailDto = {
  companyId?: string | null;
  membershipId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  dateOfBirth: string;
  email?: string | null;
  mobile?: string | null;
};

export type TestChaserNotificationDto = {
  schemeId: string;
  employerId: string;
  payrollId: string;
};

export type DisablePayrollDto = {
  employerId: string;
  schemeId: string;
  payrollId: string;
};

export type PayrollResponsePagedResponse = {
  totalCount: number;
  items: PayrollResponse[] | null;
};

export type EmployerResponsePagedResponse = {
  totalCount: number;
  items: EmployerResponse[] | null;
};

export type EmployerPayrollResponse = {
  payrollRequired: boolean;
  canEnablePayrollRequired: boolean;
};

export type EnableDisablePayrollRequiredDto = {
  payrollRequired: boolean;
};

export type UpdatePayrollRequiredModel = {
  id?: string;
  schemeId?: string;
  isPayrollRequired?: boolean;
};

export type EmployeeResponse = {
  payrollReference: string | null;
  title: string | null;
  surname: string | null;
  forenames: string | null;
  gender: string | null;
  dateOfBirth: string | null;
  email: string | null;
  espr: string | null;
  isRemoved: boolean;
  mobile: string | null;
  niNumber: string | null;
  ninoNotApplic: string | null;
  address1: string | null;
  address2: string | null;
  address3: string | null;
  address4: string | null;
  address5: string | null;
  postcode: string | null;
  id: string;
  updatedDate: string | null;
};

export type PersonalDetailsResponse = {
  id: string;
  title: string | null;
  forename: string | null;
  surname: string | null;
  dateOfBirth: string;
  niNumber?: string | null;
  email: string | null;
  gender: string | null;
  maritalStatus: string | null;
  address1: string | null;
  address2: string | null;
  address3?: string | null;
  address4?: string | null;
  address5?: string | null;
  postcode: string | null;
  countryCode?: string | null;
};

export type MembershipDetailsResponse = {
  memberShipId: string;
  employeeId: string;
  dateJoinedScheme: string;
  dateOfLeaving?: string;
  exitReasonCode?: ExitReasonCode;
};

export type ExtendedEmployerResponse = {
  id: string;
  name: string | null;
  address1: string | null;
  address2: string | null;
  address5: string | null;
  address4: string | null;
  address3: string | null;
  postcode: string | null;
  email: string | null;
  hasPaymentPlan: boolean;
  schemeId: string;
  schemeName: string | null;
  sectionId: string | null;
  strategicPartnerId: string | null;
  updatedDate: string | null;
  createdDate: string;
  epsr: string | null;
  paymentMethod: string;
  adminPlatformSyncEnabled: boolean;
  payrollRequired: boolean;
  canEnablePayrollRequired: boolean;
};

export type GeneralDetailsResponse = {
  employeeId: string;
  payrollReference: string | null;
  dateJoinedCompany: string;
  employer: string | null;
  employerId: string;
  schemeId: string;
};

export type EmployeePartTimeResponse = {
  id: string;
  partTimeStartDate: string;
  partTimeEndDate: string | null;
  partTimeType: PartTimeType | null;
  currentFTEHours: number | null;
  currentPartTimeHours: number | null;
  currentPartTimePercentage: number | null;
};

export type EmployeePartTimeResponsePagedResponse = {
  totalCount: number;
  items: EmployeePartTimeResponse[];
};

export type CreateEmployeePartTimeModel = {
  employeeId?: string;
  partTimeStartDate: string;
  partTimeEndDate?: string | null;
  partTimeType?: PartTimeType | null;
  currentFTEHours?: number | null;
  currentPartTimeHours?: number | null;
  currentPartTimePercentage?: number | null;
};

export type UpdateEmployeePartTimeModel = {
  id: string;
  employeeId?: string;
  partTimeStartDate: string;
  partTimeEndDate?: string | null;
  partTimeType?: PartTimeType | null;
  currentFTEHours?: number | null;
  currentPartTimeHours?: number | null;
  currentPartTimePercentage?: number | null;
  comment?: string;
};

export type ShortEmployerResponse = {
  id: string;
  name: string | null;
  adminPlatformSyncEnabled: boolean;
};

export type EmployeeBreakInServiceResponse = {
  id: string;
  breakInServiceStartDate: string;
  breakInServiceEndDate: string | null;
  breakInServiceType: BreakInServiceType;
  breakInServiceDescription: string;
};

export type EmployeeBreakInServiceDetailResponse = {
  id: string;
  employeeId: string;
  payrollEmployeeDataId: string;
  breakInServiceStartDate: string;
  breakInServiceEndDate: string | null;
  breakInServiceType: BreakInServiceType;
  breakInServiceDescription: string;
};

export type EmployeeBreakInServiceDetailResponsePagedResponse = {
  totalCount: number;
  items: EmployeeBreakInServiceDetailResponse[];
};

export type CreateBreakInServiceModel = {
  employeeId?: string;
  breakInServiceStartDate: string;
  breakInServiceEndDate?: string | null;
  breakInServiceType: BreakInServiceType;
  breakInServiceDescription: string;
};

export type UpdateBreakInServiceDto = {
  id: string;
  employeeId?: string;
  breakInServiceStartDate: string;
  breakInServiceEndDate?: string | null;
  breakInServiceType: BreakInServiceType;
  breakInServiceDescription: string;
  comment?: string;
};

export type TemplateDefinitionSearchResponse = {
  id: string;
  name: string | null;
  createdDate: string;
  updatedDate: string | null;
};

export type TemplateDefinitionSearchResponsePagedResponse = {
  totalCount: number;
  items: TemplateDefinitionSearchResponse[];
};

export type SalaryTypesSearchResponse = {
  id: string;
  salaryType: string | null;
  friendlyName: string | null;
  createdDate: string;
  updatedDate: string | null;
};

export type SalaryTypesSearchResponsePagedResponse = {
  totalCount: number;
  items: SalaryTypesSearchResponse[] | null;
};

export type SalaryTypeResponse = {
  id: string;
  salaryType: string;
  friendlyName: string;
};

export type CreateSalaryTypeModel = {
  salaryType: string;
  friendlyName: string;
};

export type UpdateSalaryTypeModel = {
  id: string;
  salaryType: string;
  friendlyName: string;
};

export type EmployeeSalaryDetailResponse = {
  id: string;
  startDate: string;
  salaryType: string;
  value: number;
};

export type EmployeeSalaryDetailResponsePagedResponse = {
  totalCount: number;
  items: EmployeeSalaryDetailResponse[];
};

export type CreateEmployeeSalaryDetailModel = {
  employeeId?: string;
  startDate: string;
  salaryType: string;
  salaryValue: number;
};

export type UpdateEmployeeSalaryDetailModel = {
  id: string;
  employeeId?: string;
  salaryValue: number;
  comment?: string;
};

export type ConfigureReportModel = {
  schemeId: string;
  reportType: string;
  frequency: ReportFrequency;
};

export type UpdateReportModel = {
  id: string;
  schemeId: string;
  frequency: ReportFrequency;
};

export type ReportResponse = {
  id: string;
  schemeId: string;
  schemeName: string | null;
  reportType: string;
  frequency: ReportFrequency;
};

export type ConfigurationReportSearchResponsePagedResponse = {
  totalCount: number;
  items: ConfigurationReportSearchResponse[] | null;
};

export type ConfigurationReportSearchResponse = {
  id: string;
  reportType: string;
  schemeName: string | null;
  frequency: ReportFrequency;
  createdDate: string;
  updatedDate: string | null;
  nextRunDate: string;
};

export type MemberAuditSearchResponse = {
  id: string;
  userName: string | null;
  createdDate: string;
  method: AuditMethod;
};

export type MemberAuditSearchResponsePagedResponse = {
  totalCount: number;
  items: MemberAuditSearchResponse[];
};

export type MemberAuditResponse = {
  id: string;
  sourceFileId: string | null;
  payrollReference: string | null;
  companyName: string | null;
  schemeName: string | null;
  userName: string | null;
  createdDate: string;
  method: AuditMethod;
  comment: string | null;
  previousValues: string | null;
  newValues: string | null;
};

export type DataExtractRequestAuditResponsePagedResponse = {
  totalCount: number;
  items: DataExtractRequestAuditResponse[];
};

export type DataExtractRequestAuditResponse = {
  id: string;
  userName: string | null;
  date: string;
};

export type SchemeAuditResponsePagedResponse = {
  totalCount: number;
  items: SchemeAuditResponse[];
};

export type SchemeAuditResponse = {
  id: string;
  userName: string | null;
  createdDate: string;
  payrollReference: string | null;
  schemeName: string | null;
  schemeId: string;
  method: AuditMethod;
};
