import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';
import { RouteAction } from 'src/types';

type PayrollState = {
  payrollRef: string;
  payrollEmployeeId: string;
  payrollMemberEdit: RouteAction;
};

const initialState: PayrollState = {
  payrollRef: '',
  payrollEmployeeId: '',
  payrollMemberEdit: RouteAction.View,
};

const payrollSlice = createSlice({
  name: 'payroll',
  initialState,
  reducers: {
    setPayrollRef(state: PayrollState, action: PayloadAction<string>) {
      state.payrollRef = action.payload;
    },

    setPayrollEmployeeId(state: PayrollState, action: PayloadAction<string>) {
      state.payrollEmployeeId = action.payload;
    },

    setPayrollMemberAction(state: PayrollState, action: PayloadAction<RouteAction>) {
      state.payrollMemberEdit = action.payload;
    },

    resetPayroll(state: PayrollState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Cleanup
    builder.addCase(resetStore, (state: PayrollState) => {
      Object.assign(state, initialState);
    });
  },
});

export const { setPayrollRef, setPayrollEmployeeId, setPayrollMemberAction, resetPayroll } = payrollSlice.actions;

export default payrollSlice.reducer;
