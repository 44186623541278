import qs from 'qs';
import { useState, useMemo, useEffect, useCallback, PropsWithChildren } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'src/store';
import { isLoggedInSelector } from 'src/store/selectors/authSelector';
import { selectedCompanyIdSelector } from 'src/store/selectors/companySelector';
import { selectCompany } from 'src/store/slices/companySlice';

import { history } from 'src/router';

import { useIsMount } from '@itm/shared-frontend/lib/hooks';

export const preserveKeyList = ['companyId'] as const;

type SearchParams = Partial<Record<(typeof preserveKeyList)[number], string>>;
function SyncFiltersWithURL(props: PropsWithChildren<object>) {
  const isMount = useIsMount();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const selectedCompanyId = useSelector(selectedCompanyIdSelector);
  const { search: locationSearch } = useLocation();
  const { companyId = '' } = useMemo<SearchParams>(() => qs.parse(locationSearch.slice(1)), [locationSearch]);
  const [isLoading, setIsLoading] = useState(true);

  const syncCompanyId = useCallback(() => {
    if (companyId === selectedCompanyId) return;
    if (isMount) {
      dispatch(selectCompany(companyId));
    } else {
      const { pathname, search, state } = history.location;
      const query = search.slice(1);
      const searchParams = qs.parse(query);

      searchParams.companyId = selectedCompanyId;
      navigate({ pathname, search: qs.stringify(searchParams) }, { state, replace: true });
    }
  }, [companyId, selectedCompanyId, isMount, dispatch, navigate]);

  useEffect(() => {
    syncCompanyId();
    setIsLoading(false);
  }, [syncCompanyId]);

  return <>{!isLoading || !isLoggedIn ? props.children : null}</>;
}

export default SyncFiltersWithURL;
