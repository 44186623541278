import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

export const payrollSliceSelector = (state: RootState) => state.payroll;

export const payrollRefSelector = createSelector(payrollSliceSelector, (state) => state.payrollRef);

export const payrollEmployeeIdSelector = createSelector(payrollSliceSelector, (state) => state.payrollEmployeeId);

export const payrollMemberActionSelector = createSelector(payrollSliceSelector, (state) => state.payrollMemberEdit);
