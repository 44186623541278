import { employerHubApi } from 'src/api';
import { AxiosRequestConfig } from 'axios';
import { SchemeResponse } from 'src/types';

export const getSchemeListByCompanyId = (companyId: string, config: AxiosRequestConfig = {}) =>
  employerHubApi.get<SchemeResponse[]>(`/api/v1/schemes/assignedToCompany/${companyId}`, config);

export const getSchemeById = (schemeId: string, config: AxiosRequestConfig = {}) =>
  employerHubApi.get<SchemeResponse>(`/api/v1/schemes/${schemeId}`, config);

export const getSchemeWithDataAccessListByCompanyId = (companyId: string, config: AxiosRequestConfig = {}) =>
  employerHubApi.get<SchemeResponse[]>(`/api/v1/schemes/dataAccessSchemesByCompanyId/${companyId}`, config);
